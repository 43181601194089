import React, {
  Suspense,
  lazy,
  useEffect,
  useState
} from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useIdleTimer, EventsType } from 'react-idle-timer'
import disableReactDevTools from './libs/disableReactDevTools';
import { Auth0Provider } from "@auth0/auth0-react";

import { AppContext } from './context/AppContext';


import {
  AppContextType,
  DataPointSearchFilterType,
  UserLocationType,

  HttpResponseDataType
} from './libs/types';

import {
  TIME_OUT,
  BACKEND_SERVER_MODE,
  SHOW_DEBUG_INFO,
} from './libs/constants';

import {
  getGlobalTokenReq,
  requestAPI,
  end_session,
  getWindowCount,
  setWindowCount,

  deleteMainWindowId,
  getMainWindowId,
  updateMainWindowId,
  GenerateRandomString,
  getSessionId,

  myLogger,
} from './libs/functions';

import useSessionId from './hooks/useSessionId';

// import HomePage from './pages/HomePage';
// import EditDataPointPage from './pages/EditDataPointPage';
// import HotelDetailPage from './pages/HotelDetailPage';
// import DataPointDetailPage from './pages/DataPointDetailPage';
// import UserCenterPage from './pages/UserCenterPage';
// import MapViewPage from './pages/MapViewPage';



const HomePage = lazy(() => import('./pages/HomePage'));
const EditDataPointPage = lazy(() => import('./pages/EditDataPointPage'));
const HotelDetailPage = lazy(() => import('./pages/HotelDetailPage'));
const DataPointDetailPage = lazy(() => import('./pages/DataPointDetailPage'));
const UserCenterPage = lazy(() => import('./pages/UserCenterPage'));
const MapViewPage = lazy(() => import('./pages/MapViewPage'));
const HotelMapPage = lazy(() => import('./pages/HotelMapPage'));
const HyattTablePage = lazy(() => import('./pages/HyattTablePage'));
// const MarriottPage = lazy(()=> import('./pages/MarriottPage'));
const UserPublicProfilePage = lazy(() => import('./pages/UserPublicProfilePage'));
const MattressRunMapPage = lazy(() => import('./pages/MattressRunMapPage'));
const ProgramInfoPage = lazy(()=> import('./pages/ProgramInfoPage'));
// const HyattFNPage = lazy(()=>import('./pages/HyattFNPage'));
// const HiltonFNPage = lazy(()=> import("./pages/HiltonFNPage"));
const HotelFNPage = lazy(()=> import('./pages/HotelFNPage'));
const SimpleHotelListWithMapPage = lazy(()=> import('./pages/SimpleHotelListWithMapPage'));
const UserReservationDetailPage = lazy(()=> import('./pages/UserReservationDetailPage'));
const UserLinkProfilePage = lazy(()=> import('./pages/UserLinkProfilePage'));

const UserReservationFormPage = lazy(()=> import('./pages/UserReservationFormPage'));
const UserReservationClaimPage = lazy(()=> import('./pages/UserReservationClaimPage'));
const RateSearchPage = lazy(()=> import('./pages/RateSearchPage'));

const Err503Page = lazy(()=> import('./pages/Err503Page'));
const Err404Page = lazy(()=> import('./pages/Err404Page'));

// for test
const BrandLogoListPage = lazy(() => import('./pages/BrandLogoListPage'));
const TestPage = lazy(()=> import("./pages/TPage"));


const defaultAppContextData: DataPointSearchFilterType = { page: 1 };
const defaultUserLocation: UserLocationType = {} as UserLocationType;

if (process.env.NODE_ENV === "production") disableReactDevTools();


const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/hotel/:hotelId", element: <HotelDetailPage /> },
  { path: "/data-point/:uid", element: <DataPointDetailPage /> },
  { path: "/map", element: <MapViewPage /> },
  { path: "/all-hotels", element: <HotelMapPage /> },
  { path: "/map/hilton-resorts", element: <SimpleHotelListWithMapPage /> },
  { path: "/map/hyatt-bonus-journeys", element: <SimpleHotelListWithMapPage /> },
  { path: "/map/hyatt-category-changes", element: <HyattTablePage /> },
  { path: "/submit-data-point", element: <EditDataPointPage key={'create'}/>,  },
  { path: "/submit-data-point/:hotel_uid", element: <EditDataPointPage key={'update'}/>,   },
  { path: "/modify-data-point/:uid", element:  <EditDataPointPage key={'update'}/> },
  { path: "/user-center/:action", element: <UserCenterPage /> },
  { path: "/user-center", element: <UserCenterPage /> },
  { path: "/rate-quote", element: <UserReservationFormPage /> },
  { path: "/rate-quote/claim/:task_id", element: <UserReservationClaimPage /> },
  { path: "/rate-quote/:task_id", element: <UserReservationFormPage /> },

  { path: "/rate-search/", element: <RateSearchPage /> },

  { path: "/marriott-free-night-recommend", element: <HotelFNPage /> },
  { path: "/hyatt-free-night-recommend", element: <HotelFNPage /> },
  { path: "/hilton-free-night-recommend", element: <HotelFNPage /> },
  { path: "/ihg-free-night-recommend", element: <HotelFNPage /> },
  { path: "/user/:pid", element: <UserPublicProfilePage /> },
  { path: "/reservation/:uid", element: <UserReservationDetailPage /> },
  { path: "/user-link-profile", element: <UserLinkProfilePage /> },
  { path: "/mattress-run-map", element: <MattressRunMapPage /> },
  { path: "/preferred-program/hyatt-prive", element: <ProgramInfoPage hotelGroup="hyatt" /> },
  { path: "/preferred-program/marriott-stars-luminous", element: <ProgramInfoPage hotelGroup="marriott" /> },
  { path: "/preferred-program/hilton-for-luxury", element: <ProgramInfoPage hotelGroup="hilton" /> },
  { path: "/preferred-program/ihg-destined", element: <ProgramInfoPage hotelGroup="ihg" /> },
  { path: "/preferred-program/accor-hera", element: <ProgramInfoPage hotelGroup="accor" /> },
  { path: "/hotel-offers", element: <SimpleHotelListWithMapPage /> },
  { path: "/err503", element: <Err503Page /> },
];
// 添加条件路由
if (SHOW_DEBUG_INFO) {
  routes.push({ path: "/brands", element: <BrandLogoListPage /> });
  routes.push({ path: "/test", element: <TestPage /> });
}

// 添加 404 路由
routes.push({ path: "*", element: <Err404Page /> });

const router = createBrowserRouter(routes);




function App() {

  const [dataPointSearchFilter, setDataPointSearchFilter] = useState<DataPointSearchFilterType>(defaultAppContextData);
  const [userLocation, setUserLocation] = useState(defaultUserLocation);
  const [sessionId, setSessionId] = useState<string>('');
  const [windowId, setWindowId] = useState<string>('');
  const [auth0Token, setAuth0Token] = useState<string>('')

  const session_id = useSessionId();

  const onWindowOpen = () => {
    let windowCount = getWindowCount();
    console.debug("App():onWindowOpen(): windowCount:", windowCount);
    if (windowCount === -1) {
      setWindowCount(1)
    } else {
      setWindowCount(windowCount + 1)
    }


    let mainWindowId = getMainWindowId();

    if (mainWindowId === null) {
      console.debug("App():onWindowOpen(): seems main window")
      // main window
      let mWid = GenerateRandomString(16);
      updateMainWindowId(mWid);
      setWindowId(mWid)
    } else if (mainWindowId != null && getWindowCount() === 1) {
      console.debug("App():onWindowOpen(): seems need act as main window")
      setWindowId(mainWindowId)
    } else {
      console.debug("App():onWindowOpen(): seems other window")
      setWindowId(GenerateRandomString(16))
    }
  }

  const onWindowClose = () => {

  }

  const getGlobalSessionId = (reason: string) => {
    let session_id = getSessionId();
    if (session_id.length === 0) {
      getGlobalTokenReq(reason, window.location.href).then((res: HttpResponseDataType) => {
        console.debug("App():getGlobalTokenReq():res:", res);
        if (res && res?.code === 0 && res.session_id) {
          setSessionId(res.session_id);
          localStorage.setItem('session_id', res.session_id);
          localStorage.setItem('status', 'active');
        }
      })
    }
  }

  const onIdle = () => {
    end_session('onIdle');
  }

  const onActive = (event: Event | undefined) => {
    // Do some active action
    getGlobalSessionId('go active');
    localStorage.setItem('status', 'active');

    let mainWindowId = getMainWindowId();

    console.debug("onActive():mainWindowId:", mainWindowId);

    if (mainWindowId === null) {
      console.debug("onActive():need update mainWindowId", mainWindowId);
      let mWid = GenerateRandomString(16);
      updateMainWindowId(mWid);
      // setWindowId(mWid)
    }
  }

  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: TIME_OUT, })

  useEffect(() => {
    myLogger(`current window id:${windowId}`);

    const handleTabClose = (event: BeforeUnloadEvent) => {
      // onWindowClose()
      
      let windowCount = getWindowCount();
      console.debug("onWindowClose(): windowCount:", windowCount);
      myLogger(`onWindowClose(): start ,wID:${windowId}`);
      myLogger(`onWindowClose(): windowCount:${windowCount}`);
      if (windowCount <= 1) {
        // setSessionId("");
        console.debug("onWindowClose(): last window, call end_session()");
        myLogger(`onWindowClose(): last window, call end_session()`);
        end_session('closeWindow');
        localStorage.removeItem('wCount');
      } else {
        console.debug("onWindowClose(): still have window, NOT call end_session()");
        myLogger(`onWindowClose(): still have window, NOT call end_session())`);
        setWindowCount(windowCount - 1)
      }

      let mainWindowId = getMainWindowId();
      console.debug("onWindowClose():mainWindowId:", mainWindowId);
      console.debug("onWindowClose():windowId:", windowId);
      myLogger(`onWindowClose():mainWindowId:${mainWindowId}`);
      myLogger(`onWindowClose():windowId:${windowId}`);

      if (mainWindowId === windowId) {
        console.debug("onWindowClose(): main window, need delete mWid");
        myLogger(`onWindowClose():main window, need delete mWid`);
        deleteMainWindowId();
      }
      myLogger(`onWindowClose(): end ,wID:${windowId}`);
      return;
    };

    const handleStorageChange = (event: StorageEvent) => {
      console.debug("handleStorageChange(): event:", event);
      let mWid = getMainWindowId();
      console.debug("handleStorageChange(): mWid:", mWid);
      if (mWid === null) {
        console.debug("handleStorageChange(): main window is close. act as new main window");
        console.debug("handleStorageChange(): mWid change to:", windowId);
        updateMainWindowId(windowId);
      }
    }
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [windowId]);


  useEffect(() => {
    document.title = 'HotelFT.com';
    // onActive(undefined);
    // getGlobalSessionId('app init')
    console.debug("App(): session_id:", session_id);
    if (!session_id || (session_id && session_id?.length <= 7)) {
      console.debug("App():need init session id.");
      getGlobalSessionId('app init')
    } else {
      console.debug("App(): maybe open new tab/window.");
      console.debug("App(): use session id:", session_id);
    }
    onWindowOpen();
    return () => {
    };
  }, []);


  // useEffect(()=>{
  //   console.debug("App():location:", location);
  // }, [location])
  return (
    <Auth0Provider
      domain="uscreditcardguide.auth0.com"
      clientId="STqVHlkmwODhWuVOKr0gPpRaHMup2y4w"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <AppContext.Provider value={{ dataPointSearchFilter, setDataPointSearchFilter, userLocation, setUserLocation, sessionId, setSessionId, windowId, setWindowId, auth0Token, setAuth0Token}}>
        
          <Suspense >
            <RouterProvider router={router} />
          </Suspense>
        
      </AppContext.Provider>
    </Auth0Provider>
  )
}


export default App;
import { StylesConfig } from 'react-select';

const SHOW_DEBUG_INFO = false;


function getServerAddrWithBackendServerMode(backendMode:"prod"|"test"|"local"): string {
  return backendMode === 'prod' ? 'https://api.hotelft.com/' :  (backendMode === 'test' ?  'https://api.hotelft-test.com/' : 'http://localhost:8000/');
}

function getWebAddrWithBackendServerMode(backendMode:"prod"|"test"|"local"): string {
  return backendMode === 'prod' ? 'https://www.hotelft.com/' : (backendMode === 'test' ? 'https://www.hotelft-test.com/' : 'http://localhost:3000/');
}

function getTimeOutWithBackendServerMode(backendMode:"prod"|"test"|"local"): number {
  return backendMode === 'prod' ? 30 : 60; // 30 min for prod; 60min for test/local
}



// test: for test server
// prod: for prod server
// local: for local dev server
const BACKEND_SERVER_MODE = "prod";

// const SERVER_ADDR =  BACKEND_SERVER_MODE === 'prod' ? 'https://api.hotelft.com/' :  (BACKEND_SERVER_MODE === 'test' ?  'https://api.hotelft-test.com/' : 'http://localhost:8000/');
const SERVER_ADDR = getServerAddrWithBackendServerMode(BACKEND_SERVER_MODE);

const HOTEL_API_ADDR = `${SERVER_ADDR}data/`;
// const WEB_ADDR = BACKEND_SERVER_MODE === 'prod' ? 'https://www.hotelft.com/' : (BACKEND_SERVER_MODE === 'test' ? 'https://www.hotelft-test.com/' : 'http://localhost:3000/')   ;
const WEB_ADDR = getWebAddrWithBackendServerMode(BACKEND_SERVER_MODE);

const auth0_login_params = { redirectUri: `${WEB_ADDR}user-center/login` };

const minute = getTimeOutWithBackendServerMode(BACKEND_SERVER_MODE);

const TIME_OUT = 1000 * 60 * minute;
console.debug("TIME_OUT:", TIME_OUT);
console.debug("BACKEND_SERVER_MODE:", BACKEND_SERVER_MODE);


const REMOTE_LOG_ENABLED = false;
const REACT_APP_SEND_LOG_ENDPOINT = 'http://localhost:5000/api/logger';
const REACT_APP_RETRIEVE_LOGS_ENDPOINT = 'http://localhost:5000/api/logs';


const BUILD_NUMBER = 2706;

const PAGES = [
  {
    name: '数据点',
    name_en: 'Home',
    link: '/',
  },
  {
    name: '酒店信息/地图',
    name_en: 'Map View',
    link: '/map',
  },
  {
    name: 'Hyatt Privé',
    name_en: 'Hyatt Privé',
    link: '/program-info#hyatt',
  },
  {
    name: 'Marriott STARS & LUMINOUS',
    name_en: 'Marriott STARS & LUMINOUS',
    link: '/program-info#marriott',
  },
  {
    name: '万豪房券推荐地图',
    name_en: 'Marriott Free Night Recommend Page',
    link: '/marriott-free-night-recommend',
  },
  {
    name: '刷房地图',
    name_en: 'mattress run map',
    link: '/mattress-run-map',
  },
  {
    name: '提交数据点',
    name_en: 'Submit Data Point',
    link: '/submit-data-point',
  },
  {
    name: '用户中心',
    name_en: 'User Center',
    link: '/user-center',
  },
];

const PAGE_DATA_OF_MENU = [
  {
    name: '数据点',
    name_en: 'Home',
    link: '/',
  },
  {
    name: '常用地图',
    name_en: 'Maps',
    link: '/map',
    children: [
      {
        name: '酒店信息/地图',
        name_en: 'Map View',
        link: '/map',
      },
      {
        name: 'Hyatt Privé',
        name_en: 'Hyatt Privé',
        link: '/program-info#hyatt',
      },
      {
        name: 'Marriott STARS & LUMINOUS',
        name_en: 'Marriott STARS & LUMINOUS',
        link: '/program-info#marriott',
      },
      {
        name: '万豪房券推荐地图',
        name_en: 'Marriott Free Night Recommend Page',
        link: '/marriott-free-night-recommend',
      },
      {
        name: '刷房地图',
        name_en: 'mattress run map',
        link: '/mattress-run-map',
      },
    ],
  },
  {
    name: '提交数据点',
    name_en: 'Submit Data Point',
    link: '/submit-data-point',
  },
  {
    name: '用户中心',
    name_en: 'User Center',
    link: '/user-center',
  },
];

// const MAPVIEW_PAGES = [
//   {
//     name: "数据点",
//     name_en: "Home",
//     link: "/",
//   },
//   {
//     name: '酒店信息/地图',
//     name_en: "Map View",
//     link: '/map',
//   },
//   {
//     name: 'Hyatt专题页',
//     name_en: 'Hyatt Event Page',
//     link: '/hyatt-2023-category-change'
//   },
//   {
//     name: '提交数据点',
//     name_en: "Submit Data Point",
//     link: '/submit-data-point',
//   },
//   {
//     name: '搜索',
//     name_en: 'Search',
//     link: 'toggleSearchBox'
//   },
//   {
//     name: '用户中心',
//     name_en: "User Center",
//     link: '/user-center',
//   }
// ]

const MOBILE_DEVICE_MAX_WIDTH = 820;

const customSelectStyles: StylesConfig = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    // borderBottom: '1px dotted pink',
    // color: state.selectProps.menuColor,
    // padding: 20,
    zIndex: 2999,
  }),

  control: (provided, state) => {
    // console.debug("control:provided:", provided);
    // console.debug("control:state:", state);
    return {
      ...provided,
      // width: '120px'
      minHeight: '56px',
      // backgroundColor: "#F2F2F2",
      backgroundColor: '#FFF',
      // zIndex: 1999,
    };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      // opacity,
      // transition
      // zIndex: 2999,
    };
  },
};

const paymentTypeStyles: StylesConfig = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    // borderBottom: '1px dotted pink',
    // color: state.selectProps.menuColor,
    // padding: 20,
    zIndex: 100,
  }),

  control: (provided, state) => {
    // console.debug("control:provided:", provided);
    // console.debug("control:state:", state);
    return {
      ...provided,
      // width: '120px'
      minHeight: '56px',
      // backgroundColor: "#F2F2F2",
      backgroundColor: '#FFF',
      // zIndex: 12999,
    };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      // opacity,
      // transition
      // zIndex: 2999,
    };
  },
};

export {
  SERVER_ADDR,
  WEB_ADDR,
  HOTEL_API_ADDR,
  SHOW_DEBUG_INFO,
  BACKEND_SERVER_MODE,
  // CATEGORY_OPTIONS,
  PAGES,
  PAGE_DATA_OF_MENU,
  MOBILE_DEVICE_MAX_WIDTH,

  // modal_style_desktop,
  // modal_style_mobile,
  customSelectStyles,
  paymentTypeStyles,
  auth0_login_params,
  BUILD_NUMBER,
  TIME_OUT,
  REACT_APP_SEND_LOG_ENDPOINT,
  REACT_APP_RETRIEVE_LOGS_ENDPOINT,
  REMOTE_LOG_ENABLED,
};
